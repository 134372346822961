import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { getShopListByRegion } from 'Store/StoreLocator/Selector/ShopListByRegion.selector';

import Swogo from './Swogo.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Pwa/Component/Swogo/Container/mapStateToProps */
export const mapStateToProps = (state, props) => ({
    shops: getShopListByRegion(state, props),
});

/** @namespace Pwa/Component/Swogo/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace Pwa/Component/Swogo/Container */
export class SwogoContainer extends PureComponent {
    static propTypes = {
        bundleId: PropTypes.number.isRequired,
        showNotification: PropTypes.func.isRequired,
        addProduct: PropTypes.func.isRequired,
    };

    componentDidMount() {
        window.addEventListener('swogo-add-to-cart', this.handleSwogoAddToCart);
    }

    componentWillUnmount() {
        window.removeEventListener('swogo-add-to-cart', this.handleSwogoAddToCart);
    }

    _getBundleId() {
        const { bundleId } = this.props;

        return bundleId;
    }

    containerProps = () => ({
        bundleId: this._getBundleId(),
    });

    handleSwogoAddToCart = (evt) => {
        const { addProduct, showNotification } = this.props;
        const { detail: { products } } = evt;

        // eslint-disable-next-line no-restricted-syntax
        for (const product of products) {
            addProduct({
                products: [{
                    sku: product.sku,
                    quantity: product.qty,
                }],
            }).then(
                /** @namespace Pwa/Component/Swogo/Container/SwogoContainer/then/catch/addProduct/then */
                () => {
                    window.dispatchEvent(new CustomEvent('swogo-add-to-cart-finished'), {
                        sku: product.sku,
                    });
                    showNotification('success', __('Product added to cart!'));
                }
            ).catch(
                /** @namespace Pwa/Component/Swogo/Container/SwogoContainer/then/catch */
                () => {
                    window.dispatchEvent(new CustomEvent('swogo-add-to-cart-failed'), {
                        sku: product.sku,
                    });
                }
            );
        }
    };

    render() {
        return (
            <Swogo
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwogoContainer);
